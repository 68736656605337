/* global history google Image mwl_map MapController */

import L from 'leaflet'
import { Loader } from '@googlemaps/js-api-loader'

jQuery(document).ready(function ($) {

  window.mwl_pro_create_deeplinking_hash = function (index) {
    if ($.isNumeric(index)) {
      window.location.hash = 'mwl-' + index
      return window.location.href
    }
  }

  window.mwl_pro_remove_deeplinking_hash = function () {
    history.pushState("", document.title, window.location.pathname + window.location.search)
  }

  window.mwl_pro_add_social_sharing = function (currentUrl) {
    const facebookShareURL = 'https://www.facebook.com/sharer/sharer.php?u=' + currentUrl
    const twitterShareURL = 'https://twitter.com/intent/tweet?text=' + currentUrl
    const pinterestShareURL = 'https://pinterest.com/pin/create/button/?url=' + currentUrl + '&media=&description='
    let sharingOptionsMarkup = [
      '<li><a href=' + facebookShareURL + ' target="_blank">Share on Facebook</a></li>',
      '<li><a href=' + twitterShareURL + ' target="_blank">Share on Twitter</a></li>',
      '<li><a href=' + pinterestShareURL + ' target="_blank">Share on Pinterest</a></li>'
    ]
    sharingOptionsMarkup = sharingOptionsMarkup.join('')
    $('.mwl__topbar__controls__control--sharing').css('display', 'inline-block')
    $('.mwl__topbar__controls__control--sharing__options').html(sharingOptionsMarkup)
  }

  /**
   * Preload an image
   * @param  {[type]} image_src    [image src attribute]
   * @param  {[type]} image_srcset [image srcset attribute]
   * @param  {[type]} image_sizes  [image sizes attribute]
   * @return void
   */
  window.mwl_pro_preload_image = function (image_src, image_srcset) {
    function preload(sources) {
      const images = []
      for (let i = 0, length = sources.length; i < length; ++i) {
        images[i] = new Image()
        images[i].src = sources[i]
      }
    }
    const sources = []
    if (image_srcset) {
      const splitted_srcset = image_srcset.split(" ")
      splitted_srcset.forEach(function (item) {
        if (item.indexOf('http') !== -1) {
          sources.push(item)
        }
      })
    }
    else if (image_src) {
      sources.push(image_src)
    }
    preload(sources)
  }

  window.MapController = function (map_settings, data) {
    this.data = data
    this.container_id = map_settings.container_id
    this.map = null
    this.center = map_settings.center
    this.tiles_provider = map_settings.tiles_provider
    this.lightboxable = true
    this.tokens = {
      googlemaps: mwl_map.googlemaps.api_key,
      mapbox: mwl_map.mapbox.api_key,
      maptiler: mwl_map.maptiler.api_key
    }
    this.styles = {
      mapbox: mwl_map.mapbox.style,
      googlemaps: mwl_map.googlemaps.style
    }


    this.createMap = function (callback) {
      if (this.tiles_provider === 'googlemaps') {
        const loader = new Loader({
          apiKey: this.tokens[this.tiles_provider],
          version: "weekly"
        })

        loader.load().then(() => {
          this.map = new google.maps.Map(document.getElementById(this.container_id), {
            center: { lat: -34.397, lng: 150.644 },
            zoom: 8
          })

          this.map.setOptions({ styles: this.styles.googlemaps })

          callback()
        })
      } else {
        if (L.DomUtil.get(this.container_id) != null) {
          L.DomUtil.get(this.container_id)._leaflet_id = null
          this.map = L.map(this.container_id).setView(this.center, 13)

          callback()
        }
      }
    }

    this.addTilesLayer = function () {
      if (this.tiles_provider == 'openstreetmap') {
        const url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        const attribution = 'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors'
        L.tileLayer(url, {
          attribution: attribution,
          maxZoom: 18,
          noWrap: true,
          style: 'https://openmaptiles.github.io/osm-bright-gl-style/style-cdn.json'
        }).addTo(this.map)
      }
      if (this.tiles_provider == 'maptiler') {
        const url = 'https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=' + this.tokens[this.tiles_provider]
        const attribution = '© MapTiler © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors'
        L.tileLayer(url, {
          attribution: attribution,
          maxZoom: 18,
          noWrap: true
        }).addTo(this.map)
      }
      if (this.tiles_provider == 'mapbox') {
        let url
        if (this.styles.mapbox.username && this.styles.mapbox.style_id) {
          const mapbox_style = this.styles.mapbox
          url = 'https://api.mapbox.com/styles/v1/' + mapbox_style.username + '/' + mapbox_style.style_id + '/tiles/{z}/{x}/{y}?access_token=' + this.tokens[this.tiles_provider]
        } else {
          url = 'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=' + this.tokens[this.tiles_provider]
        }
        const attribution = 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
        L.tileLayer(url, {
          attribution: attribution,
          maxZoom: 18,
          id: 'mapbox.streets'
        }).addTo(this.map)
      }
    }

    function getLargestImageAvailable(image) {
      if (image.sizes.large) {
        return image.sizes.large
      }
      if (image.sizes.medium) {
        return image.sizes.medium
      }
      if (image.sizes.thumbnail) {
        return image.sizes.thumbnail
      }
    }

    function createGmapMarkers(data, app) {
      function CustomMarker(latlng, map, imageSrc) {
        this.latlng_ = latlng
        this.imageSrc = imageSrc
        this.setMap(map)
      }

      CustomMarker.prototype = new google.maps.OverlayView()

      CustomMarker.prototype.draw = function () {
        let div = this.div_
        if (!div) {
          div = this.div_ = document.createElement('div')
          div.className = "gmap-image-marker"


          const img = document.createElement("img")
          img.src = this.imageSrc
          div.appendChild(img)

          const panes = this.getPanes()
          panes.overlayImage.appendChild(div)
        }

        const point = this.getProjection().fromLatLngToDivPixel(this.latlng_)
        if (point) {
          div.style.left = point.x + 'px'
          div.style.top = point.y + 'px'
        }
      }

      CustomMarker.prototype.remove = function () {
        if (this.div_) {
          this.div_.parentNode.removeChild(this.div_)
          this.div_ = null
        }
      }

      CustomMarker.prototype.getPosition = function () {
        return this.latlng_
      }

      for (let i = 0; i < data.length; i++) {
        const img_gps_as_array = data[i].data.gps.split(',')
        const image = {
          image: getLargestImageAvailable(data[i]),
          pos: [img_gps_as_array[0], img_gps_as_array[1]]
        }
        new CustomMarker(new google.maps.LatLng(image.pos[0], image.pos[1]), app.map, image.image)
      }
    }

    function createLeafletMarker(index, image, app) {
      let lightboxable
      app.lightboxable ? lightboxable = 'inline-block' : lightboxable = 'none'
      let image_marker_markup = [
        '<div class="image-marker-container" data-image-index="' + index + '">',
        '<div class="rounded-image" style="background-image: url(' + getLargestImageAvailable(image) + ')">',
        '<img src="' + getLargestImageAvailable(image) + '" srcset="' + image.file_srcset + '" sizes="' + image.file_sizes + '" style="display: ' + lightboxable + '">',
        '</div>',
        '</div>'
      ]
      image_marker_markup = image_marker_markup.join('')

      const icon = L.divIcon({
        className: 'image-marker',
        iconSize: null,
        html: image_marker_markup
      })

      const gps_as_array = image.data.gps.split(',')
      const pos = gps_as_array

      return L.marker(pos, { icon: icon })
    }

    this.addMarkers = function () {
      if (this.tiles_provider === 'googlemaps') {
        createGmapMarkers(data, this)
      } else {
        this.data.forEach((index, image) => {
          createLeafletMarker(image, index, this).addTo(this.map)
        })
      }
    }

    this.fitMarkers = function () {
      if (this.tiles_provider === 'googlemaps') {
        const bounds = new google.maps.LatLngBounds()
        this.data.forEach(image => {
          const gps_as_array = image.data.gps.split(',')
          const pos = {
            lat: parseFloat(gps_as_array[0]),
            lng: parseFloat(gps_as_array[1])
          }
          bounds.extend(pos)
        })

        this.map.fitBounds(bounds)
      } else {
        const latLngArray = []
        this.data.forEach(image => {
          const imageLatLng = image.data.gps.split(',')
          latLngArray.push(imageLatLng)
        })

        const bounds = new L.LatLngBounds(latLngArray)

        this.map.fitBounds(bounds)
      }
    }
  }

  window.mwlInitMap = function (map_id, image) {
    const map_settings = {
      tiles_provider: mwl_map.default_engine,
      container_id: map_id,
      center: [parseFloat(image.img_gps.lat), parseFloat(image.img_gps.lng)]
    }

    const map_data = [
      {
        file: 'lightbox_image_file',
        file_srcset: image.img_srcset,
        file_sizes: image.image_sizes,
        dimension: image.img_dimensions,
        sizes: {
          thumbnail: image.img_low_res_src,
          medium: image.img_low_res_src,
          large: image.img_src
        },
        data: {
          caption: image.img_exifs.caption,
          gps: `${image.img_gps.lat},${image.img_gps.lng}`
        }
      }
    ]

    const mapController = new MapController(map_settings, map_data)
    mapController.createMap(() => {
      mapController.addTilesLayer()
      mapController.addMarkers()
      mapController.fitMarkers()
    })

    $('body').on('removeMwlMap', function () {
      mapController.removeMap()
    })
  }

})
